<template>
  <div class="legend-panel">
    <panel v-show="isOpen" class="legend-panel__container">
      <h4>
        <slot name="header" />
      </h4>
      <div class="legend-panel__content">
        <slot>loading ...</slot>
      </div>
    </panel>
    <div
      class="legend-panel__button"
      :class="{ close: !isOpen }"
      @click="isOpen = !isOpen"
    >
      <i class="fas" :class="isOpen ? 'fa-minus-square' : 'fa-list'" />
    </div>
  </div>
</template>

<script>
import Panel from '@anagraph/panel'
import { computed } from '@vue/composition-api'
export default {
  components: {
    Panel
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isOpen = computed({
      get: () => {
        return props.open
      },
      set: val => {
        emit('update:open', val)
      }
    })
    return { isOpen }
  }
}
</script>

<style lang="scss">
.legend-panel {
  .panel {
    width: initial;
    min-height: initial;
  }
  position: absolute;
  right: 50px;
  top: 50px;
  &__button {
    padding: 16px;
    background-color: white;
    position: absolute;
    right: 0px;
    top: 0px;
    &.close {
      padding: 8px;
      box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.26);
    }
  }
  &__content {
    display: flex;
    flex: 1;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    // text-align: left;
    padding: 36px;
    padding-top: 45px;
  }
}
</style>
