import ad742 from '@/data-config/ad-r742'
import sdr6 from '@/data-config/sdr-r6'
import sdr742 from '@/data-config/sdr-r742'
function createSteps(config, defaultColor) {
  const steps = config.reduce((result, item) => {
    return [...result, '#' + item.color, parseInt(item.max)]
  }, [])
  return [...steps, defaultColor]
}
export default [
  {
    id: 'ad-r6',
    url: 'https://dev.warehouse.anagraph.io/t//statscan.demo2016_ad',
    params: {
      properties: ['r6']
    },
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'r6'], 0],
          '#1e043f',
          1,
          '#440154',
          1000,
          '#3A528B',
          2500,
          '#20908D',
          5000,
          '#5DC962',
          7500,
          '#FDE725',
          10000,
          '#B86B25'
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'ad-r742',
    url: 'https://dev.warehouse.anagraph.io/t//statscan.demo2016_ad',
    params: {
      properties: ['r742']
    },
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'r742'], 0],
          ...createSteps(ad742, 'grey')
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'sdr-r742',
    url: 'https://dev.warehouse.anagraph.io/t//statscan.demo2016_sdr',
    params: {
      properties: ['r742']
    },
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'r742'], 0],
          ...createSteps(sdr742, 'grey')
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  },
  {
    id: 'sdr-r6',

    url: 'https://dev.warehouse.anagraph.io/t//statscan.demo2016_sdr',
    params: {
      properties: ['r6']
    },
    type: 'fill',
    style: {
      paint: {
        'fill-color': [
          'step',
          ['to-number', ['get', 'r6'], 0],
          ...createSteps(sdr6, 'grey')
        ],
        'fill-opacity': 0.75
      }
    },
    before: 'road_minor_case'
  }
]
