import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/commons/auth/firebase'
import makeAuthApp from '@/commons/auth/authController'

import 'normalize.css'

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
let app
if (process.env.VUE_APP_AUTH_ENABLED) {
  const callback = makeAuthApp(App, app, store, router, 'Login', ['Home'])
  auth.onAuthStateChanged(callback)
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
