import Vue from 'vue'
export default function(
  App,
  app,
  store,
  router,
  loginPage = 'Login',
  protectedPages = ['Home']
) {
  const callback = async user => {
    if (!app) {
      app = new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    }
    if (!process.env.VUE_APP_AUTH_ENABLED) return
    if (user) {
      await store.dispatch('auth/connect', true)
      await store.dispatch('auth/fetchToken')
      if (app.$route.query.to || !protectedPages.includes(app.$route.name)) {
        app.$router.replace(
          app.$route.query.to ? { name: app.$route.query.to } : { path: '/' }
        )
      }
    } else {
      app.$router.replace({
        name: loginPage,
        query: { to: app.$route.name }
      })
    }
  }
  return callback
}
