<template>
  <legend-panel :open.sync="open" class="legend">
    <template v-slot:header>Légende</template>
    <chloropleth-discrete-legend :items="items" />
  </legend-panel>
</template>

<script>
import ad742 from '@/data-config/ad-r742'
import ad6 from '@/data-config/ad-r6'
import sdr6 from '@/data-config/sdr-r6'
import sdr742 from '@/data-config/sdr-r742'
import LegendPanel from '@/commons/components/legend/LegendPanel'
import ChloroplethDiscreteLegend from '@/commons/components/legend/chloropleth-discrete-legend.vue'
export default {
  components: {
    LegendPanel,
    ChloroplethDiscreteLegend
  },
  props: {
    layer: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      open: true
    }
  },
  computed: {
    items() {
      return this.layer === 'sdr-r6'
        ? sdr6
        : this.layer === 'sdr-r742'
        ? sdr742
        : this.layer === 'ad-r6'
        ? ad6
        : this.layer === 'ad-r742'
        ? ad742
        : []
    }
  }
}
</script>
<style lang="scss">
.legend {
  text-align: left;
  max-width: 250px;
  .panel {
    min-height: 300px;
  }
}
</style>
