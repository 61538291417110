<template>
  <div class="layer-selector">
    <div class="layer-selector__item">
      <h3>indicateur</h3>
      <v-select
        :selected.sync="indicateur"
        :options="indicateurOptions"
        style="font-size:14px"
      />
    </div>
    <div class="layer-selector__item">
      <h3>territoire</h3>
      <v-select
        :selected.sync="territoire"
        :options="territoireOptions"
        style="font-size:14px"
      />
    </div>
  </div>
</template>

<script>
import VSelect from './Select'
export default {
  components: {
    VSelect
  },
  data() {
    return {
      indicateur: 'r6',
      indicateurOptions: [
        {
          id: 'r6',
          label: 'densité de population'
        },
        {
          id: 'r742',
          label: 'revenus'
        }
      ],
      territoire: 'sdr',
      territoireOptions: [
        {
          id: 'ad',
          label: 'aire de diffusion'
        },
        {
          id: 'sdr',
          label: 'Subdivisions de recensement'
        }
      ]
    }
  },
  computed: {
    selection() {
      return this.territoire + '-' + this.indicateur
    }
  },
  watch: {
    indicateur() {
      this.select()
    },
    territoire() {
      this.select()
    }
  },
  methods: {
    select() {
      this.$emit('select', this.selection)
    }
  }
}
</script>

<style lang="scss" scoped>
.layer-selector {
  min-height: 400px;
  text-align: left;
  margin: 40px 10px;
  &__item {
    padding-bottom: 40px;
  }
}
</style>
