<template>
  <div>
    <gl-map
      ref="mapRef"
      :init-map-options="options"
      @loaded="$emit('loaded')"
    />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import { shallowRef, ref, toRefs } from '@vue/composition-api'
import { buildVectorLayerConfig } from './layerHelper'
import { useToggleLayers, useMapboxPopup } from '@anagraph/composable-map'

import layers from './layers.js'
export default {
  components: {
    GlMap
  },
  props: {
    visibleLayers: {
      type: Array,
      default: () => ['densite-de-population-2']
    }
  },
  setup(props, { emit }) {
    const { visibleLayers } = toRefs(props)
    const mapRef = shallowRef(null)
    const myLayers = ref(layers.map(buildVectorLayerConfig))
    useToggleLayers(mapRef, myLayers, visibleLayers)
    layers.forEach(({ id }) => {
      useMapboxPopup(mapRef, id)
    })

    const defaultZoom = 7
    return {
      mapRef,
      options: { zoom: defaultZoom, minZoom: 1, maxZoom: 20, hash: false }
    }
  }
}
</script>

<style lang="scss" scoped></style>
