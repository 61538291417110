import firebase from 'firebase/app'
import 'firebase/auth'
import config from '@/config'
let auth
if (config?.firebase.apiKey && !firebase.apps.length) {
  firebase.initializeApp(config.firebase)
  auth = firebase.auth()
}

export { auth }
