<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template v-slot:left-panel>
        <app-panel class="home__left-panel">
          <template v-slot:header><h2>Carto Jeunes</h2></template>
          <template v-slot:body>
            <div v-if="mapLoaded">
              <layer-selector @select="select" />
            </div>
            <div v-else>loading ...</div>
          </template>
          <template v-slot:footer>une réalisation Anagraph</template>
        </app-panel>
      </template>
      <template v-slot:map>
        <Map :visible-layers="visibleLayers" @loaded="mapLoaded = true" />
      </template>
    </left-panel-layout>
    <panel v-if="false" class="home__right-panel" />
    <map-legend :layer="layerSelected" />
  </div>
</template>

<script>
import LeftPanelLayout from '@anagraph/left-panel-layout'
import Map from '@/components/map'
import Panel from '@anagraph/panel'
import AppPanel from '@anagraph/panel/app-panel'
import LayerSelector from '@/components/layer-selector.vue'
import MapLegend from '@/components/legend'
export default {
  name: 'Home',
  components: {
    LeftPanelLayout,
    Map,
    AppPanel,
    Panel,
    LayerSelector,
    MapLegend
  },
  data() {
    return {
      mapLoaded: false,
      layerSelected: 'sdr-r6'
    }
  },
  computed: {
    visibleLayers() {
      return [this.layerSelected]
    }
  },
  methods: {
    select(selected) {
      this.layerSelected = selected
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  &__left-panel {
    height: 100%;
  }
  &__right-panel {
    position: absolute;
    width: 300px;
    background-color: rgb(241, 237, 237);
    z-index: 500;
    top: 50px;
    right: 50px;
  }
}
</style>
