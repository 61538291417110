<template>
  <div>
    <div v-for="(item, index) in formattedItems" :key="index" class="color">
      <div
        class="color-rectangle"
        :style="{ 'background-color': '#' + item.color }"
      ></div>
      <div v-if="item.min && item.max">{{ item.min }} à {{ item.max }}</div>
      <div v-if="!item.max">plus de {{ item.min }}</div>
      <div v-if="!item.min">moins de {{ item.max }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { min: 0, max: 1000, color: 'blue' },
        { min: 1000, max: 2000, color: 'chartreuse' },
        { min: 2000, max: 3000, color: 'tomato' },
        { min: 3000, color: 'hotpink' }
      ]
    },
    format: {
      type: Function,
      default: item => new Intl.NumberFormat('fr-CA').format(item)
    }
  },
  computed: {
    formattedItems() {
      return this.items.map(({ min, max, color }) => ({
        min: min ? this.format(min) : min,
        max: max ? this.format(max) : max,
        color
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  align-items: center;
  &-rectangle {
    width: 16px;
    height: 16px;
    border: solid lightgrey 1px;
    border-radius: 5px;
    margin: 6px 8px;
  }
}
</style>
